import React from "react";
import * as ReactSurvey from "survey-react";
import * as _ from "lodash";
import "survey-react/survey.css";
import "./Survey.css";
import logo from "../../images/Skiplist.jpg";
import ReactLoading from "react-loading";
import SurveyBuilder from "../../lib/survey-builder";

class Survey extends React.Component {
  state = {
    surveyType: null
  };
  onComplete(survey, options) {
    const { uuid } = this.props.match.params;
    fetch(`${process.env.REACT_APP_API_SERVER}/project_survey/uuid/${uuid}`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ uuid, data: survey.data, submitted: true })
    }).then(async payload => {});
  }

  componentDidMount() {
    const { uuid } = this.props.match.params;
    fetch(
      `${process.env.REACT_APP_API_SERVER}/project_survey/uuid/${uuid}`
    ).then(async payload => {
      try {
        const body = await payload.json();
        this.setState({ surveyType: body.type, taken: body.taken });
      } catch {}
    });
  }
  render() {
    if (this.state.surveyType && !this.state.taken) {
      let surveyFile = SurveyBuilder.getSurveyByType(this.state.surveyType);
      let json = surveyFile.surveyStart;
      let firstPage = SurveyBuilder.buildPage("Customer Sastifcation");
      firstPage.elements = SurveyBuilder.buildQuestionPage(
        surveyFile.questions
      );
      json.pages.push(firstPage);
      const model = new ReactSurvey.Model(json);
      return (
        <div>
          <div className="header">
            <img src={logo} alt="Skiplist" className="logo" />
            <span className={"survey_title"}>
              {_.startCase(_.toLower(this.state.surveyType)) + " Survey"}
            </span>
          </div>
          <ReactSurvey.Survey
            model={model}
            onComplete={this.onComplete.bind(this)}
          />
          ;
        </div>
      );
    } else if (this.state.taken) {
      return (
        <div className="container">
          <img
            src={logo}
            alt="Skiplist"
            className="logo"
            style={{ marginLeft: -30 }}
          />
          <p>This survey has already been taken.</p>
        </div>
      );
    } else {
      return (
        <div className="container">
          <img
            style={{ marginLeft: -35 }}
            src={logo}
            alt="Skiplist"
            className="logo"
          />
          <ReactLoading
            height={100}
            width={200}
            type={"spinningBubbles"}
            color={"#00b4f8"}
          />
        </div>
      );
    }
  }
}

export default Survey;
