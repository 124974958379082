import React from "react";
import "./App.css";
import "./index.css";
import gothamBold from "./fonts/gotham-bold.woff";
import gothamBook from "./fonts/gotham-book.woff";
import gothamMedium from "./fonts/gotham-medium.woff";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Survey from "./components/Survey/Survey";

function App() {
  return (
    <Router>
      <div>
        <Route path="/:uuid" component={Survey} />
      </div>
    </Router>
  );
}

export default App;
