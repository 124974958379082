import postSigningSurvey from "./surveys/post-signing.json";
import postKickoffSurvey from "./surveys/post-kickoff.json";
import postDeliverySurvey from "./surveys/post-delivery.json";
import milestoneSurvey from "./surveys/milestone.json";
import developerSurvey from "./surveys/developer.json";
import productSurvey from "./surveys/product.json";
import internalSurvey from "./surveys/internal.json";
const SurveyBuilder = {};

SurveyBuilder.getSurveyByType = type => {
  switch (type) {
    case "POST SIGNING":
      return postSigningSurvey;
    case "POST KICKOFF":
      return postKickoffSurvey;
    case "MILESTONE":
      return milestoneSurvey;
    case "DEVELOPER":
      return developerSurvey;
    case "PRODUCT":
      return productSurvey;
    case "INTERNAL":
      return internalSurvey;
    case "POST PROJECT":
      return postDeliverySurvey;
    default:
      return { questions: [], surveyStart: {} };
  }
};
SurveyBuilder.buildPage = pageName => {
  return {
    name: pageName,
    elements: []
  };
};

SurveyBuilder.buildQuestionPage = questions => {
  return questions
    .map(question => {
      switch (question.type) {
        case "rating":
          return SurveyBuilder.buildRatingQuestion(
            question.name,
            question.title,
            question.isRequired,
            question.minRateDescription,
            question.maxRateDescription
          );
          break;
      }
    })
    .flat();
};
SurveyBuilder.buildRatingQuestion = (
  name,
  title,
  isRequired,
  minRateDescription,
  maxRateDescription
) => {
  return [
    {
      type: "rating",
      name,
      title,
      isRequired,
      rateMin: 0,
      rateMax: 10,
      minRateDescription,
      maxRateDescription
    },
    {
      type: "comment",
      name: `${name}_passive_experience`,
      visibleIf: `{${name}} >= 5`,
      title: "What is the primary reason for your score?"
    },
    {
      type: "comment",
      name: `${name}_disappointed_experience`,
      visibleIf: `{${name}} <= 4`,
      title: "What was disappointing/incorrect in your experience with us?"
    }
  ];
};

export default SurveyBuilder;
